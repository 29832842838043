import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import LayoutComponent from "../LayoutComponent"
// Blocks
import { URL } from "../../config/settings"
import AsideBlock from "../../blocks/articles-page/aside.block"
import ArticleBlock from "../../blocks/articles-page/articles.block"
import { Seo } from "../seo"
export interface FilterTempProps {}

const FilterTemp: React.SFC<FilterTempProps> = (props: {
  path: any
  data: any
  pageContext: any
}) => {
  const pathCat = props.pageContext.slugcat
  const dataGRAPH = props.data.allStrapiArticle.nodes

  const [content, setContent] = useState()
  useEffect(() => {
    // setContent(Content)
    if (props.path !== "/") {
      const HEADER = document.querySelector(".content-header")
      const HEADER_MOBIL = document.querySelector(".header-mobile")
      HEADER?.classList.add("swap-header")
      HEADER_MOBIL?.classList.add("change-backgorund")
    }
  }, [])
  return (
    <>
      <Seo
        title={`Super Sonic Design INC | ${pathCat}`}
        image={dataGRAPH.length > 0 ? dataGRAPH[0].Image[0].url : ""}
        description={pathCat}
        url={`https://supersonicdesign.com/articles/${pathCat}`}
      />
      <LayoutComponent>
        <div className="container container-articles">
          <div className="row no-gutters">
            <div className="col-lg-9">
              <div className="container">
                <h3>
                  All categories of:
                  <span className="u-text-capitalize">{pathCat}</span>
                  {/* {dataGRAPH[0].category === undefined
                  ? ""
                  : dataGRAPH[0].category.NameCategory} */}
                  {/* {dataGRAPH[0] === null
                  ? ""
                  : dataGRAPH[0].category.NameCategory} */}
                </h3>
                <div className="row">
                  {dataGRAPH.map((card, i) => (
                    <div className="col-lg-6 col-md-6" key={i}>
                      <div className="card-article">
                        <Link to={`/articles/${card.Slug}`}>
                          <img
                            className="card-article__image"
                            // src={`${URL}${image}`}
                            src={`${URL}${card.Image[0].url}`}
                            alt="Banner Card"
                          />
                        </Link>

                        <div className="card-article__create-by">
                          <div>
                            {card.author_of_article === null ? "" : 
                              <p>                              
                                <span>{card.author_of_article.NameAuthor}</span>
                              </p>
                            }
                            <p>                              
                              <span>{card.PublicationDate}</span>
                            </p>
                          </div>
                          {card.category === null ? "" : 
                            <Link
                            to={`/articles/${card.category.SlugCat}`}
                            className={`btn-categorie btn-categorie--${card.category.NameCategory}`}
                            >
                              {card.category.NameCategory}
                            </Link>
                          }
                          
                        </div>
                        <Link
                          className="card-article__description"
                          to={`/articles/${card.Slug}`}
                        >
                          <p>{card.Title.substr(0, 45)}</p>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <AsideBlock />
            </div>
          </div>
        </div>
      </LayoutComponent>
    </>
  )
}

export default FilterTemp

export const query = graphql`
  query($slugcat: String!) {
    allStrapiArticle(
      sort: { fields: PublicationDate, order: DESC }
      filter: { category: { SlugCat: { eq: $slugcat } } }
    ) {
      nodes {
        category {
          NameCategory
          SlugCat
        }
        author_of_article {
          NameAuthor
        }
        PublicationDate
        Content
        Image {
          url
        }
        Title
        Slug
      }
    }
  }
`
