import React from "react"
import HeaderComponent from "../components/HeaderComponent"
import FooterComponent from "../components/FooterComponent"
import Helmet from "react-helmet"
import NewHeaderComponent from "./NewHeaderComponent"
export interface LayoutComponentProps {}

const LayoutComponent: React.SFC<LayoutComponentProps> = ({ children }) => {
  return (
    <>
      <NewHeaderComponent/>
      {children}
      <FooterComponent />
    </>
  )
}

export default LayoutComponent
