import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { URL } from "../../config/settings"
export interface AsideBlockProps {}

const AsideBlock: React.SFC<AsideBlockProps> = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allStrapiArticle(
        sort: { fields: PublicationDate, order: DESC }
        limit: 10
      ) {
        nodes {
          author_of_article {
            NameAuthor
          }
          category {
            NameCategory
            SlugCat
          }
          Image {
            url
          }
          Slug
          Title
        }
      }
    }
  `)
  const dataGRAPH = data.allStrapiArticle.nodes
  return (
    <div className="content-aside">
      <h2>Recent Posts</h2>
      {dataGRAPH.map((rows, i) => (
        <div className="content-aside__row" key={i}>
          <div className="content-aside__row__column">
            <Link to={`/articles/${rows?.Slug}`}>
              <img
                className="content-aside__row__image"
                // src={`${URL}${rows.Image[0].url}`}
                src={`${URL}${rows?.Image[0].url}`}
                alt={rows?.Title}
              />
            </Link>
            <Link to={`/articles/${rows?.Slug}`}>
              <p>{rows?.Title.substr(0, 35)}</p>
            </Link>
          </div>
          <div className="content-aside__create-by">
            {rows?.author_of_article && (
              <p>
                <span>By {rows?.author_of_article?.NameAuthor}</span>
              </p>
            )}
            {rows.category && (
              <Link
                to={`/articles/${rows?.category?.SlugCat}`}
                className={`btn-categorie btn-categorie--${rows?.category?.NameCategory}`}
              >
                {rows?.category?.NameCategory}
              </Link>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export default AsideBlock
